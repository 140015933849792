import React, { useState, useEffect } from "react"
import {
  DetailsContainer,
  DetailsHeader,
  DetailText,
  FrequencyText,
} from "./styles"
import { v4 as uuidv4 } from "uuid"
import Check from "../../images/icons/Check.svg"
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { Subscription } from "../../redux/subscription"

type Props = {
  checkoutComplete?: boolean
  currentSubscription?: Subscription
}

const View = ({ checkoutComplete, currentSubscription }: Props) => {
  const [customStyles, setCustomStyles] = useState({
    borderBottom: "",
    marginBottom: "",
    padding: ""
  })
  // eslint-disable-next-line
  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const formatNumberToDollars = (num: number) => {
    num /= 100

    const numberWithCommas = num
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return `$${numberWithCommas}.00`
  }

  const formatDuration = (duration: any) => {
    return Number(duration) === 12 ? "year" : "month"
  }

  useEffect(() => {
    if (currentSubscription?.name && products) {
      setCustomStyles({
        borderBottom:
        products[0]?.name.indexOf(currentSubscription.name) !== -1
        ? "none"
        : "",
        marginBottom:
        products[0]?.name.indexOf(currentSubscription.name) !== -1
        ? "0"
        : "",
        padding:
        products[0]?.name.indexOf(currentSubscription.name) !== -1
        ? "24px"
        : "",
      })
    }
  }, [currentSubscription, products])

  return products ? (
    // Check if current active subscription is same as cart product subscription
    // 2023 Products include "Plan" in name, use indexOf to compare
    // EX: "ARE 5.0 Expert 12-Month Subscription Plan".indexOf("ARE 5.0 Expert 12-Month Subscription")
    currentSubscription?.name && products[0]?.name.indexOf(currentSubscription.name) !== -1 ? (
      <DetailsContainer>
        <DetailsHeader
          style={{
            flexDirection: "column",
            alignItems: "unset",
            justifyContent: "center",
            ...customStyles
          }}
        >
          <Typography variant="body1">
            You are already subscribed to the{" "}
            <b>{currentSubscription.name}.</b>
            <br />
            <br />
            Please return to the pricing page and select a different plan.
          </Typography>
        </DetailsHeader>
      </DetailsContainer>
    ) : (
      <>
        {products.map((product) => {
          const { display_name, duration, amount, product_details } = product
          const details = product_details.split(",")

          return (
            <DetailsContainer key={uuidv4()}>
              <DetailsHeader>
                <Typography variant="h4">{display_name}</Typography>
                {checkoutComplete ? null : (
                  <Typography variant="h4">
                    {formatNumberToDollars(amount)}
                    <FrequencyText>/{formatDuration(duration)}</FrequencyText>
                  </Typography>
                )}
              </DetailsHeader>

              {product?.name.includes("ARE 5.0") ? (
                <Typography
                  variant="h4"
                  style={{ padding: "0 24px 24px 24px" }}
                >
                  Included for all 6 ARE divisions:
                </Typography>
              ) : null}

              {product?.name.includes("Expert") ? (
                <DetailText key="pass-the-are-gurantee">
                  <img src={Check} alt="" style={{ paddingRight: 16 }} />
                  <Typography
                    variant="body1"
                    style={{ fontFamily: "Georgia, sans-serif" }}
                  >
                    Pass the ARE Guarantee
                  </Typography>
                </DetailText>
              ) : null}

              {details[0] &&
                details.map((detail) => (
                  <DetailText key={uuidv4()}>
                    <img src={Check} alt="" style={{ paddingRight: 16 }} />
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "Georgia, sans-serif" }}
                    >
                      {detail}
                    </Typography>
                  </DetailText>
                ))}
            </DetailsContainer>
          )
        })}
      </>
    )
  ) : null
}

export default View
