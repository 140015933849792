import React, { useState, useEffect } from "react"
import { CartContainer, CartHeader, ReturnText } from "./styles"
import CartDetails from "../CartDetails"
import CartDetailsB2B from "../CartDetailsB2B"
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import ReturnArrow from "../../images/icons/ReturnArrow.svg"
import { Subscription } from "../../redux/subscription"

type Props = {
  currentSubscription?: Subscription
}

const View = ({currentSubscription}: Props) => {
  const [productType, setProductType] = useState("")
  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )
  const [productCount, setProductCount] = useState(0)

  const handleRedirect = (type: string) => {
    window.dataLayer.push({ event: "returnToPricingPage" })

    if (type === "individual") {
      window.location.href = `${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}`
    } else {
      window.location.href = `${process.env.REACT_APP_HUBSPOT_B2B_REDIRECT_URL}`
    }
  }

  useEffect(() => {
    if (products?.length) {
      const product = products[0]
      setProductType(product?.audience)
      setProductCount(products.length)
    }
  }, [products])

  return (
    <>
      <CartContainer>
        <CartHeader>
          <Typography variant="h2">
            Your order ({productCount <= 1 ? "1 item" : `${productCount} items`}
            )
          </Typography>

          <ReturnText>
            <Typography
              variant="body1"
              onClick={() =>
                productType === "individual"
                  ? handleRedirect("individual")
                  : handleRedirect("group")
              }
            >
              <img src={ReturnArrow} style={{ paddingRight: 8 }} alt="" />
              Update Selection
            </Typography>
          </ReturnText>
        </CartHeader>

        {productType === "individual" ? (
          <CartDetails checkoutComplete={false} currentSubscription={currentSubscription}/>
        ) : (
          <CartDetailsB2B checkoutComplete={false} />
        )}
      </CartContainer>
    </>
  )
}
export default View
