import React from "react"
import View from "./view"
import { Subscription } from "../../redux/subscription"

type Props = {
  currentSubscription?: Subscription
}
const CartContainer = ({currentSubscription}: Props) => {
  return <View currentSubscription={currentSubscription}/>
}

export default CartContainer
