import React from "react"
import View from "./view"
import { Subscription } from "../../redux/subscription"

type Props = {
  checkoutComplete?: boolean
  currentSubscription?: Subscription
}

const CartDetails = ({ checkoutComplete, currentSubscription }: Props) => {
  return <View checkoutComplete={checkoutComplete} currentSubscription={currentSubscription}/>
}

export default CartDetails
