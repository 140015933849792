import React, { useState, useEffect } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import { createSubscription } from "../../redux/network/payment"
import { upgrade } from "../../redux/network/upgrade"
import SecureCheckout from "../SecureCheckout"
import { Typography } from "@mui/material"
import {
  CheckoutBox,
  FormBox,
  BorderedBox,
  BlueBox,
  BlueLabel,
} from "../SecureCheckout/styles"
import { Button } from "../Button"
import {
  CreditCardContainer,
  ButtonBox,
  PaymentInfoHeader,
  HelperText,
} from "./styles"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import ConfirmationModal from "../Confirmation"
import { attachSubscription } from "../../redux/network/payment"
import { validatePaymentMethod } from "../../redux/validatePaymentMethod"
import VisaCard from "../../images/icons/VisaCard.svg"
import MasterCard from "../../images/icons/MasterCard.svg"
import DiscoverCard from "../../images/icons/DiscoverCard.svg"
import AMEXCard from "../../images/icons/AMEXCard.svg"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import ErrorIcon from "../../images/error-icon.svg"
import { useAuth } from "../../providers/AuthProvider"

interface Props {
  setPage: (num: number) => void
  widget?: boolean
  currentSubscription?: any
  creditCardInfoAvailable?: boolean
  creditCardInfo?: CreditCardInfo
  billingInfo?: BillingInfo
  phoneDetails?: PhoneInfo
  getPaymentInfo?: () => void
  pageLoadingComplete: boolean
  couponCode?: string
  setCouponCode?: (value: string) => void
}

type CreditCardInfo = {
  brand: string
  last_4: string
  exp_month: string
  exp_year: string
}

type BillingInfo = {
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: string
}

type PhoneInfo = {
  phoneNumber: string
  phoneExtension: string
}

type Card = {
  VISA: any
  MASTERCARD: any
  DISCOVER: any
  AMEX: any
}

const View = ({
  setPage,
  widget,
  currentSubscription,
  creditCardInfoAvailable,
  creditCardInfo,
  billingInfo,
  phoneDetails,
  getPaymentInfo,
  pageLoadingComplete,
  couponCode,
  setCouponCode,
}: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()

  // for confirmation page of ARE products
  const [open, setOpen] = useState(false)
  const [cardFormVisible, updateCardFormVisbile] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [backDropVisible, updateBackDropVisible] = useState(false)
  const [errorMessage, updateErrorMessage] = useState("")
  // const [checked, setChecked] = useState(false)

  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const billingInfoStoreState = useSelector(
    (state: AppState) => state.billingInfo.paymentMethods
  )

  // for customers with an active subscription
  const cartProrateDetails = useSelector(
    (state: AppState) => state.prorate.cartProrateDetails
  )

  // for customers without an active subscription
  const cartRepriceDetails = useSelector(
    (state: AppState) => state.reprice.cartDetails
  )

  const cartDetails = useSelector(
    (state: AppState) => state.reprice.cartDetails
  )

  const subscriptions = useSelector(
    (state: AppState) => state.subscription?.activeSubscription
  )

  const creditCardIconDisplay: Card = {
    VISA: VisaCard,
    MASTERCARD: MasterCard,
    DISCOVER: DiscoverCard,
    AMEX: AMEXCard,
  }

  const showCardForm = () => {
    updateCardFormVisbile(true)
  }

  const handleClick = () => {
    window.dataLayer.push({ event: "termsAndConditions" })

    window.open(
      process.env.REACT_APP_TERMS_AND_CONDITIONS_URL ||
        "https://go.blackspectacles.com/terms-and-conditions",
      "_blank"
    )
  }

  const handleClickPolicy = () => {
    window.dataLayer.push({ event: "privacyPolicy" })

    window.open("https://www.blackspectacles.com/privacy-policy","_blank")
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsDisabled(true)
    updateBackDropVisible(true)

    try {
      if (auth.isAuthenticated && products && products.length > 0) {
        // This is for users that previously had a subscription so then their payment info is saved on file and they can make a payment
        if (!currentSubscription.id) {
          // use create subscription endpoint if account does not have an existing subscription
          const paymentDetails = { ...billingInfo, ...phoneDetails }

          const pmId = billingInfoStoreState?.payment_methods[0].id

          if (!paymentDetails.phoneNumber) {
            const { payload } = await dispatch(validatePaymentMethod({ pmId }))
            const { phone } = payload.address
            paymentDetails.phoneNumber = phone
          }

          if (
            paymentDetails.address1 &&
            paymentDetails.city &&
            paymentDetails.state &&
            paymentDetails.country &&
            paymentDetails.postalCode &&
            paymentDetails.phoneNumber
          ) {
            const { payload } = await dispatch(
              createSubscription({
                auth,
                paymentDetails,
                products,
                coupon: couponCode ? couponCode : undefined,
                taxId: cartRepriceDetails ? cartRepriceDetails.tax_rate_id : "",
              })
            )

            const { cart_id } = payload
            const { subscription_id } = payload.items[0]
            const { client_secret } = payload.items[0]

            if (pmId) {
              const res = await dispatch(
                attachSubscription({
                  auth,
                  cartId: cart_id,
                  subId: subscription_id,
                  pmId,
                  clientSecret: client_secret,
                })
              )
              updateBackDropVisible(false)
              setOpen(true)

              // GA
              const items = products.map((product) => ({
                item_name: `${product?.name}`,
                item_id: `${product?.id}`,
                price: (product?.amount / 100).toFixed(2),
                item_category: ``,
                item_category2: ``,
                quantity: "1",
              }))

              let productDetails

              if (cartProrateDetails) {
                productDetails = {
                  value: (cartProrateDetails.sub_total / 100).toFixed(2),
                  tax: (cartProrateDetails.tax / 100).toFixed(2),
                  credit: (cartProrateDetails.credit / 100).toFixed(2),
                }
              } else if (cartDetails) {
                productDetails = {
                  value: (cartDetails?.sub_total / 100).toFixed(2),
                  tax: (cartDetails.tax / 100).toFixed(2),
                  credit: "0",
                }
              }

              window.dataLayer.push({
                event: "checkoutSuccess",
                price: `Total price: ${productDetails?.value}`,
                ecommerce: {
                  value: `${productDetails?.value}`,
                  credit: `${productDetails?.credit}`,
                  tax: `${productDetails?.tax}`,
                  transaction_id: `${res.payload.invoice_id}`,
                  coupon: couponCode,
                  items,
                },
              })
            }
          } else {
            throw Error("payment method not found")
          }
        } else {
          // check if current cart product is different from current subscription
          if (
            currentSubscription?.name === products[0]?.name ||
            !currentSubscription?.name
          ) {
            // display message indicating user has matching subscription
          } else {
            // upgrade/downgrade subscription
            const res = await dispatch(
              upgrade({
                auth,
                activeSubId: currentSubscription?.id,
                targetPriceId: products[0]?.id,
                coupon: couponCode,
                taxRateId: cartProrateDetails?.tax_rate_id,
              })
            )

            if (res.type === upgrade.fulfilled.type) {
              setOpen(true)

              // GA
              const items = products.map((product) => ({
                item_name: `${product?.name}`,
                item_id: `${product?.id}`,
                price: (product?.amount / 100).toFixed(2),
                item_category: ``,
                item_category2: ``,
                quantity: "1",
              }))

              let productDetails

              if (cartProrateDetails) {
                productDetails = {
                  value: (cartProrateDetails.sub_total / 100).toFixed(2),
                  tax: (cartProrateDetails.tax / 100).toFixed(2),
                  credit: (cartProrateDetails.credit / 100).toFixed(2),
                }
              } else if (cartDetails) {
                productDetails = {
                  value: (cartDetails?.sub_total / 100).toFixed(2),
                  tax: (cartDetails.tax / 100).toFixed(2),
                  credit: "0",
                }
              }

              window.dataLayer.push({
                event: "checkoutSuccess",
                price: `Total price: ${productDetails?.value}`,
                ecommerce: {
                  value: `${productDetails?.value}`,
                  credit: `${productDetails?.credit}`,
                  tax: `${productDetails?.tax}`,
                  transaction_id: `${res.payload.invoice_id}`,
                  coupon: couponCode,
                  items,
                },
              })

              // GA
            } else {
              setIsDisabled(false)
            }
          }

          updateBackDropVisible(false)
        }
      }
    } catch (error: any) {
      updateBackDropVisible(false)

      if (error.message === "payment method not found") {
        updateErrorMessage("Please update your payment method.")
      } else {
        updateErrorMessage(error.message)
      }

      setIsDisabled(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [cardFormVisible])

  useEffect(() => {
    if(products && currentSubscription?.name && products[0]?.name.indexOf(currentSubscription.name) !== -1) {
      setPage(0)
    }
  // eslint-disable-next-line
  }, [currentSubscription])

  return (
    <>
      {cardFormVisible ? (
        <SecureCheckout
          isAREProduct={true}
          updateBillingForm={true}
          setPage={setPage}
          widget={widget}
          updateCardFormVisible={updateCardFormVisbile}
          getPaymentInfo={getPaymentInfo}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
        />
      ) : (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={backDropVisible || !pageLoadingComplete}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <CheckoutBox>
            <FormBox>
              <PaymentInfoHeader>
                <Typography variant="h3">Step 2: Payment Method</Typography>
              </PaymentInfoHeader>

              {pageLoadingComplete ? (
                <>
                  <BorderedBox boxPosition={1}>
                    {(creditCardInfoAvailable &&
                      creditCardInfo &&
                      // If no phone number is available,then don't display the credit card
                      billingInfoStoreState &&
                      billingInfoStoreState.payment_methods &&
                      billingInfoStoreState.payment_methods.length &&
                      billingInfoStoreState.payment_methods[0]?.address
                        ?.phone) ||
                    widget ? (
                      <>
                        <CreditCardContainer
                          style={{ borderBottom: "0.5px solid #d8d8d8" }}
                        >
                          <div>
                            {creditCardInfoAvailable && creditCardInfo ? (
                              <>
                                <Typography variant="body1">
                                  <img
                                    src={
                                      creditCardIconDisplay[
                                        creditCardInfo?.brand.toUpperCase() as keyof Card
                                      ]
                                    }
                                    alt="Credit Card"
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  Ending in {creditCardInfo?.last_4}
                                </Typography>
                                <Typography variant="body1">
                                  Expires {creditCardInfo?.exp_month}/
                                  {creditCardInfo?.exp_year}
                                </Typography>
                              </>
                            ) : null}
                          </div>

                          <Button
                            color={"secondary"}
                            children={
                              creditCardInfoAvailable && creditCardInfo
                                ? "Update Card"
                                : "Add a new card"
                            }
                            size={"large"}
                            disabled={isDisabled}
                            style={
                              isDisabled
                                ? {
                                    color: "white",
                                  }
                                : {}
                            }
                            onClick={showCardForm}
                          />
                        </CreditCardContainer>

                        {!widget ? (
                          <form onSubmit={(e) => handleSubmit(e)}>
                            <BlueBox>
                              <Typography variant="body1">
                                <input
                                  type="checkbox"
                                  required
                                  style={{ marginRight: 10 }}
                                ></input>
                                I understand that any subscription(s) will
                                automatically renew at the start of each billing
                                cycle, and I may cancel at any time.
                              </Typography>

                              <br />

                              <Typography variant="body1">
                                By clicking "Confirm Payment", you agree to
                                Black Spectacles{" "}
                                <BlueLabel onClick={handleClick}>
                                  Terms and Conditions
                                </BlueLabel>
                                {" "}, and you acknowledge the{" "}
                                <BlueLabel onClick={handleClickPolicy}>
                                  Privacy Policy
                                </BlueLabel>
                                .
                              </Typography>
                            </BlueBox>

                            <ButtonBox style={{ borderTop: "none" }}>
                              <Button
                                className="confirmPayment"
                                color={"primary"}
                                children={"Confirm Payment"}
                                size={"large"}
                                type="submit"
                                disabled={isDisabled}
                              />
                              <Button
                                className="cancel"
                                color={"whiteGreyBorder"}
                                children={"Cancel"}
                                size={"large"}
                                disabled={isDisabled}
                                onClick={() => {
                                  setPage(0)
                                }}
                              />
                            </ButtonBox>
                          </form>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {!widget ? (
                          <CreditCardContainer>
                            <Typography variant="body1">
                              No credit card found.
                            </Typography>
                          </CreditCardContainer>
                        ) : (
                          <CreditCardContainer>
                            <Typography>
                              You do not have any active or canceled
                              subscriptions. Sign up for a new one to get
                              started!
                            </Typography>
                          </CreditCardContainer>
                        )}

                        {!widget || (widget && subscriptions?.length) ? (
                          <Button
                            color={"primary"}
                            children={"Add Card"}
                            size={"large"}
                            onClick={showCardForm}
                          />
                        ) : null}
                      </>
                    )}
                    {errorMessage ? (
                      <HelperText>
                        <img src={ErrorIcon} alt="Error" />
                        {errorMessage}
                      </HelperText>
                    ) : null}
                  </BorderedBox>
                </>
              ) : null}
            </FormBox>
          </CheckoutBox>

          {open ? <ConfirmationModal modalOpen={open} /> : null}
        </>
      )}
    </>
  )
}

export default View
